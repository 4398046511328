
import Vue from 'vue'
import Component from 'vue-class-component'
import dayjs from 'dayjs'
import UiUtils from '@/ui/util/ui-utils'

import GuideTodoList from '../common/GuideTodoList.vue'

import VISA_STEPS from './visa-guide-steps'
import LANDING_STEPS from './landing-guide-steps'
import ARRIVAL_STEPS from './living-guide-steps'

@Component({
  head: {
    title: UiUtils.translateTitle('label.pr'),
  },
  components: {
    GuideTodoList,
  },
})
export default class PrScreen extends Vue {
  visaSteps = VISA_STEPS
  minVisaDate = dayjs().subtract(1, 'year')
  maxVisaDate = dayjs()

  mounted() {
    UiUtils.scrollByRouteParams(this)
  }

  showVisaDateExplanation() {
    this.$dialog.alert({
      title: 'Date of Passport Request Letter',
      message:
        'You must complete the following steps in the Passport Request Letter within 30 days ' +
        'in order for IRCC office to issue your Confirmation of Permanent Residence and, ' +
        'if applicable, your permanent residence visa.',
    })
  }

  landingSteps = LANDING_STEPS
  minLandingDate = dayjs().subtract(1, 'year')
  maxLandingDate = dayjs().add(1, 'year')

  showLandingDateExplanation() {
    this.$dialog.alert({
      title: 'Last day of landing',
      message: 'This is the expiry date shown on your CoPR.',
    })
  }
}
